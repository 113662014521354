<template>
  <div
    v-if="promotion.sales_gift_leads && promotion.sales_gift_leads.length"
    class="pa-5"
  >
    <div class="font-weight-bold mb-2">Khách hàng nhận quà</div>

    <v-data-table :headers="headers" :items="promotion.sales_gift_leads">
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id"
        },
        {
          text: "Tên khách hàng",
          value: "name"
        },
        {
          text: "Số điện thoại",
          value: "phone"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Gift",
          value: "gift"
        },
        {
          text: "Note",
          value: "note"
        },
        {
          text: "Created at",
          value: "created_at"
        }
      ]
    };
  },

  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  }
};
</script>
