var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"calculate-widths":"","checkbox-color":"primary","disable-pagination":"","headers":_vm.HEADERS_PRODUCT_TABLE,"hide-default-footer":"","item-key":"id","items":_vm.promotion.detail.apdung_type_detail.options,"loading-text":"Đang tải dữ liệu","loading":false,"no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","show-select":""},scopedSlots:_vm._u([{key:"header.sale_price",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.promotion.detail.type === 5)?_c('v-tooltip',{attrs:{"bottom":"","content-class":"elevation-4 px-2","max-width":"400","nudge-top":"5px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"grey","dark":"","size":"14px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Số lượng tối đa trong 1 khoảng thời gian khuyến mại")])]):_vm._e()]}},{key:"body",fn:function(ref){
var items = ref.items;
var isSelected = ref.isSelected;
var select = ref.select;
return [(items.length > 0)?_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id,staticClass:"pa-2"},[_c('td',[_c('v-checkbox',{staticStyle:{"margin":"0px","padding":"0px"},attrs:{"input-value":isSelected(item),"color":"primary","hide-details":""},on:{"click":function($event){select(item, !isSelected(item))}}})],1),_c('td',[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(item.description))])])]),_c('td',[_vm._v(_vm._s(item.SKU))]),_c('td',[_vm._v(_vm._s(_vm._f("formatCurrency")(item.price)))]),_c('td',[_c('v-text-field',{staticStyle:{"width":"78px"},attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","outlined":"","placeholder":"% giảm","solo":"","type":"number","value":Math.round(
                ((item.price - item.sale_price) / item.price) * 100 * 10
              ) / 10},on:{"change":function($event){item.sale_price = item.price - (item.price * $event) / 100}}})],1),_c('td',[_c('tp-input-price',{attrs:{"custom-class":"text-body-1 mt-0 pt-0","custom-style":"width: 120px","dense":"","flat":"","hide-details":"","outlined":"","placeholder":"Giá sau giảm","solo":"","value":item.sale_price},on:{"change":function($event){item.sale_price = $event}}})],1),_c('td',[_vm._v(_vm._s(item.count_sold))]),_c('td',[_vm._v(_vm._s(item.total_sold))]),_c('td',[_c('v-text-field',{staticStyle:{"width":"55px"},attrs:{"custom-class":"text-body-1 mt-0 pt-0","dense":"","flat":"","hide-details":"","outlined":"","solo":"","value":item.max_sold},on:{"change":function($event){item.max_sold = $event}}})],1),_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.total_can_sell)+" ")]),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.handleDeleteSku(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)])}),0):_vm._e()]}}]),model:{value:(_vm.internalSelectedProduct),callback:function ($$v) {_vm.internalSelectedProduct=$$v},expression:"internalSelectedProduct"}})}
var staticRenderFns = []

export { render, staticRenderFns }