<template>
  <div class="overflow-auto">
    <div>
      <div class="font-weight-bold mb-2">Thời gian áp dụng</div>

      <div v-if="promotion.detail.type !== 5">
        <div class="mb-2">Bắt đầu</div>

        <div class="d-flex align-center">
          <div>
            <tp-input-time
              :value="promotionTimeFrom"
              custom-class="text-center text-body-1 mr-3 mt-0"
              custom-style="width: 65px"
              dense
              hide-details
              outlined
              @change="promotionTimeFrom = $event"
            ></tp-input-time>
          </div>

          <div>
            <tp-input-date
              :value="promotion.time_from"
              custom-class="text-body-1 mt-0"
              dense
              hide-details
              outlined
              placeholder="Chọn ngày"
              prepend-inner-icon="mdi-calendar"
              @change="promotion.time_from = $event"
            ></tp-input-date>
          </div>
        </div>

        <div class="mt-4">
          <div class="d-flex flex-row mb-2">
            <div>
              <v-checkbox
                v-model="promotion.is_time_to"
                :false-value="0"
                :true-value="1"
                class="mb-0 mt-0 pt-0"
                dense
                hide-details
              ></v-checkbox>
            </div>

            <div>Kết thúc</div>
          </div>

          <div v-if="promotion.is_time_to" class="d-flex align-center">
            <div>
              <tp-input-time
                :value="promotionTimeTo"
                custom-class="text-center text-body-1 mr-3 mt-0"
                custom-style="width: 65px"
                dense
                hide-details
                outlined
                @change="promotionTimeTo = $event"
              ></tp-input-time>
            </div>

            <div>
              <tp-input-date
                :value="promotion.time_to"
                custom-class="text-body-1 mt-0"
                dense
                hide-details
                outlined
                placeholder="Chọn ngày"
                prepend-inner-icon="mdi-calendar"
                @change="promotion.time_to = $event"
              ></tp-input-date>
            </div>
          </div>
        </div>
      </div>

      <div v-if="promotion.detail.type === 5" class="d-flex flex-column gap-2">
        <v-card
          v-for="(data, index) in promotion.times"
          :key="index"
          class="px-5 py-4 d-flex gap-2 justify-space-between align-center"
        >
          <div>
            <div>
              <div class="mb-2">Bắt đầu</div>

              <div class="d-flex align-center">
                <div>
                  <tp-input-time
                    :value="getTime(data.time_from)"
                    custom-class="text-center text-body-1 mr-3 mt-0"
                    custom-style="width: 65px"
                    dense
                    hide-details
                    outlined
                    @change="
                      data.time_from = setTime(
                        data.time_from,
                        $event
                      ).toISOString()
                    "
                  ></tp-input-time>
                </div>

                <div>
                  <tp-input-date
                    :value="data.time_from"
                    custom-class="text-body-1 mt-0"
                    dense
                    hide-details
                    outlined
                    placeholder="Chọn ngày"
                    prepend-inner-icon="mdi-calendar"
                    @change="data.time_from = $event"
                  ></tp-input-date>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <div class="mb-2">Kết thúc</div>

              <div class="d-flex align-center">
                <div>
                  <tp-input-time
                    :value="getTime(data.time_to)"
                    custom-class="text-center text-body-1 mr-3 mt-0"
                    custom-style="width: 65px"
                    dense
                    hide-details
                    outlined
                    @change="
                      data.time_to = setTime(data.time_to, $event).toISOString()
                    "
                  ></tp-input-time>
                </div>

                <div>
                  <tp-input-date
                    :value="data.time_to"
                    custom-class="text-body-1 mt-0"
                    dense
                    hide-details
                    outlined
                    placeholder="Chọn ngày"
                    prepend-inner-icon="mdi-calendar"
                    @change="data.time_to = $event"
                  ></tp-input-date>
                </div>
              </div>
            </div>
          </div>

          <v-btn color="red" icon @click="handleDelete(index)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-card>

        <v-btn class="w-fit" color="primary" text @click="handleAddTime">
          <v-icon left>mdi-plus</v-icon>
          Thêm ngày áp dụng
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    },

    promotionTimeFrom: {
      get() {
        return this.getTime(this.promotion.time_from);
      },
      set(val) {
        this.promotion.time_from = this.setTime(
          this.promotion.time_from,
          val
        ).toISOString();
      }
    },

    promotionTimeTo: {
      get() {
        return this.getTime(this.promotion.time_to);
      },
      set(val) {
        this.promotion.time_to = this.setTime(
          this.promotion.time_to,
          val
        ).toISOString();
      }
    }
  },

  watch: {
    "promotion.is_time_to"(value) {
      if (!value) {
        this.promotion.time_to = "";
      }
      if (value && !this.promotion.time_to) {
        this.promotion.time_to = dayjs().format();
      }
    }
  },

  methods: {
    getTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0");

      return `${hours}:${mins}`;
    },

    setTime(date, time) {
      let dateTime = new Date(date);
      const [hours, mins] = time.split(":");

      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(mins));

      return dateTime;
    },

    handleAddTime() {
      const now = dayjs();

      const newItem = {
        time_from: now.format("YYYY-MM-DD HH:mm:00"),
        time_to: now.format("YYYY-MM-DD HH:mm:00")
      };

      const newTimes = [...(this.promotion.times || []), newItem];

      this.$store.dispatch("PROMOTION/addTime", {
        ...this.promotion,
        times: newTimes
      });
    },

    handleDelete(index) {
      this.promotion.times.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.gap-2 {
  gap: 8px;
}
</style>
