<template>
  <div class="pa-5">
    <div class="font-weight-bold mb-2">Danh sách URL hiện quà tặng</div>

    <v-checkbox
      v-model="promotion.popup_show"
      class="mt-0"
      dense
      label="Hiển thị popup quà tặng"
    ></v-checkbox>

    <div v-if="promotion.popup_show" :key="key">
      <v-btn color="primary" outlined @click="addConditionUrl" class="mb-2 "
        ><v-icon left>mdi-plus</v-icon>Thêm url</v-btn
      >

      <div
        v-for="(item, index) in promotion.popup_condition_urls"
        :key="index"
        class="mt-2"
      >
        <tp-text-field
          v-model="item.url"
          placeholder="Url"
          hide-details
          validate="required"
          style="max-width: 450px;"
        ></tp-text-field>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      key: 0
    };
  },
  computed: {
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },
  methods: {
    addConditionUrl() {
      this.key++;
      console.log("addConditionUrl", this.promotion.popup_condition_urls);
      if (!this.promotion.popup_condition_urls) {
        this.promotion.popup_condition_urls = [];
      }

      this.promotion.popup_condition_urls.push({
        url: ""
      });
    }
  }
};
</script>
