<template>
  <div>
    <div class="d-flex">
      <div class="flex-1 mr-5">
        <card-title-and-code></card-title-and-code>

        <v-card class="px-5 py-5 mt-5 d-flex promotion-type-wrapper" flat>
          <div class="flex-1">
            <div class="font-weight-bold mb-2">Loại khuyến mại</div>

            <card-select-promotion-type></card-select-promotion-type>
          </div>

          <v-divider class="mx-5" vertical></v-divider>

          <card-other-info class="flex-1"></card-other-info>
        </v-card>

        <v-card class="px-5 py-5 mt-5 d-flex promotion-type-wrapper" flat>
          <div>
            <div class="font-weight-bold mb-2">Cover</div>

            <card-upload-cover
              class="card-upload-thumbnail-wrapper"
            ></card-upload-cover>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Thumbnail</div>

            <card-upload-thumbnail
              class="card-upload-thumbnail-wrapper"
            ></card-upload-thumbnail>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Background</div>

            <card-upload-background
              class="card-upload-thumbnail-wrapper"
            ></card-upload-background>
          </div>

          <div class="ml-5">
            <div class="font-weight-bold mb-2">Frame</div>

            <card-upload-frame
              class="card-upload-thumbnail-wrapper"
            ></card-upload-frame>
          </div>
        </v-card>
      </div>

      <div>
        <right-side-bar class="h-full right-sidebar-wrapper"></right-side-bar>
      </div>
    </div>

    <v-card class="mt-5" flat>
      <v-expand-transition>
        <custom-percent-or-amount
          v-if="
            promotion.detail.type === 1 ||
              promotion.detail.type === 2 ||
              promotion.detail.type === 5
          "
        ></custom-percent-or-amount>
      </v-expand-transition>

      <v-expand-transition>
        <custom-buy-x-get-y
          v-if="promotion.detail.type === 3"
          class="pa-5"
        ></custom-buy-x-get-y>
      </v-expand-transition>

      <v-expand-transition v-if="promotion.detail.type === 4">
        <card-gifts></card-gifts>
      </v-expand-transition>

      <v-expand-transition v-if="promotion.detail.type === 7">
        <card-combo></card-combo>
      </v-expand-transition>
    </v-card>

    <v-card v-if="promotion.detail.type === 4" class="mt-5" flat>
      <card-condition-url></card-condition-url>
    </v-card>

    <v-card class="mt-5" flat>
      <v-expand-transition>
        <card-gift-leads></card-gift-leads>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import CardCombo from "./components/card-combo.vue";
import CardGifts from "./components/CardGifts.vue";
import CardGiftLeads from "./components/CardGiftLeads.vue";
import CardConditionUrl from "./components/CardConditionUrl.vue";

import CardOtherInfo from "./components/CardOtherInfo";
import CardTitleAndCode from "./components/CardTitleAndCode";
import CustomBuyXGetY from "./components/CustomBuyXGetY";
import CustomPercentOrAmount from "./components/CustomPercentOrAmount";
import RightSideBar from "../RightSidebar/index.vue";
import CardUploadThumbnail from "./components/CardUploadThumbnail.vue";
import CardSelectPromotionType from "./components/CardSelectPromotionType.vue";
import CardUploadCover from "./components/CardUploadCover.vue";
import CardUploadFrame from "./components/CardUploadFrame.vue";
import CardUploadBackground from "./components/CardUploadBackground.vue";

export default {
  components: {
    CardCombo,
    CardUploadFrame,
    CardGiftLeads,
    CardConditionUrl,
    CardSelectPromotionType,
    CardUploadCover,
    CardUploadThumbnail,
    CardUploadBackground,
    RightSideBar,
    CardGifts,
    CardOtherInfo,
    CardTitleAndCode,
    CustomBuyXGetY,
    CustomPercentOrAmount
  },

  data() {
    return {
      promotionType: 1,
      selectedProducts: []
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    },
    customerGroups() {
      return this.$store.getters["CUSTOMER_GROUP/allCustomerGroups"];
    },
    productGroups() {
      return this.$store.getters["PRODUCT_GROUP/allProductGroups"];
    },
    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  watch: {
    "promotion.detail.is_code"(val) {
      if (!val) {
        this.promotion.detail.codes = null;
      }
    },
    "promotion.detail.type"(val) {
      if (val !== 3) {
        this.promotion.detail.tangkhach_type = null;
      }

      if (val === 3 && this.promotion.type === 2) {
        this.promotion.payment_condition_type = null;
      }

      if (val === 2 || val === 5) {
        this.promotion.is_limit_number_using = 0;
      }

      this.promotion.detail.tangkhach_type_detail.max_value = null;
    },
    "promotion.is_apdung_cung"(val) {
      if (val) {
        this.promotion.priority = null;
      } else {
        this.promotion.priority = 3;
      }
    },
    "promotion.is_limit_number_using"(val) {
      if (val === 0) {
        this.promotion.limit_number_using = null;
      }
    },
    // "promotion.is_time_to"(val) {
    //   if (val === 1) {
    //     if (this.promotion.time_to === null) {
    //       this.promotion.time_to = new Date().toISOString();
    //     }
    //   } else {
    //     this.promotion.time_to = null;
    //   }
    // },
    "promotion.type"(val) {
      if (val === 2) {
        this.promotion.detail.apdung_type = null;
      }

      this.promotion.detail.tangkhach_type_detail.max_value = null;
    }
  },

  methods: {
    updateFormDataThumbnail(val) {
      this.$emit("updateFormDataThumbnail", val);
    }
  }
};
</script>

<style scoped>
.main-detail-wrapper {
  height: 590px;
  overflow: auto;
}

.right-sidebar-wrapper {
  width: 355px;
  height: 590px;
  overflow: auto;
}

.promotion-type-wrapper {
  height: 430px;
}

.card-upload-thumbnail-wrapper {
  width: 265px;
}
</style>
