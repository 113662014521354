var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"font-weight-bold px-5"},[_vm._v("Tổng quan")]),_c('div',{staticClass:"d-flex flex-row px-5 mb-5"},[_c('div',{staticClass:"flex-grow-1 mr-5"},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.promotion.name ? _vm.promotion.name : "Chưa có tiêu đề")+" ")])]),(_vm.promotion.id)?_c('div',{staticClass:"justify-end"},[_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.promotion.status === 2
            ? 'blue lighten-1'
            : _vm.promotion.status === 1
            ? 'primary'
            : 'red accent-2',"outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.promotion.status === 2 ? "Lên kế hoạch" : _vm.promotion.status === 1 ? "Đang chạy" : "Đã hết hạn")+" ")])],1):_vm._e()]),_c('ul',{staticClass:"text-body-1 mx-3 pb-6",staticStyle:{"list-style":"inherit"}},[(_vm.promotion.detail.type !== 5)?_c('div',{staticClass:"mt-2"},[_c('li',[_vm._v(" Áp dụng từ "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.promotion.time_from))+" ")]),(_vm.promotion.is_time_to && _vm.promotion.time_to)?_c('span',[_vm._v(" đến "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.promotion.time_to))+" ")])]):_vm._e()])]):_vm._e(),(
        _vm.promotion.detail.type === 5 &&
          _vm.promotion.times &&
          _vm.promotion.times.length
      )?_c('div',{staticClass:"mt-2 d-flex flex-column gap-2"},_vm._l((_vm.promotion.times),function(time,index){return _c('li',{key:index},[_vm._v(" Áp dụng từ "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTime(time.time_from))+" ")]),_c('span',[_vm._v(" đến "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatDateTime(time.time_to))+" ")])])])}),0):_vm._e()]),_c('v-divider'),_c('div',{staticClass:"mt-3 mb-4 px-5"},[_c('div',{staticClass:"mb-1 font-weight-bold"},[_vm._v("Giới hạn sử dụng")]),_c('v-checkbox',{staticClass:"mt-1 mb-0",attrs:{"disabled":_vm.promotion.type === 1,"false-value":0,"true-value":1,"hide-details":"","label":"Giới hạn tổng số lượt sử dụng khuyến mại"},model:{value:(_vm.promotion.is_limit_number_using),callback:function ($$v) {_vm.$set(_vm.promotion, "is_limit_number_using", $$v)},expression:"promotion.is_limit_number_using"}}),(_vm.promotion.is_limit_number_using)?_c('tp-text-field',{staticClass:"ml-8 mt-2",staticStyle:{"width":"140px"},attrs:{"hide-details":"auto","outlined":"","placeholder":"Nhập số lượng","type":"number"},model:{value:(_vm.promotion.limit_number_using),callback:function ($$v) {_vm.$set(_vm.promotion, "limit_number_using", $$v)},expression:"promotion.limit_number_using"}}):_vm._e(),_c('v-checkbox',{staticClass:"mt-1 mb-0",attrs:{"false-value":1,"true-value":0,"hide-details":"","label":"Giới hạn một SĐT chỉ được sử dụng khuyến mại một lần"},model:{value:(_vm.promotion.limit_for_user),callback:function ($$v) {_vm.$set(_vm.promotion, "limit_for_user", $$v)},expression:"promotion.limit_for_user"}})],1),(_vm.promotion.id)?[_c('v-divider'),_c('div',[_c('div',{staticClass:"font-weight-bold my-3 px-5"},[_vm._v(" Tính hiệu quả ("+_vm._s(_vm.promotion.usingTimes)+" lượt sử dụng) ")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }