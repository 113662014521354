<template>
  <v-data-table
    v-model="internalSelectedProduct"
    calculate-widths
    checkbox-color="primary"
    disable-pagination
    :headers="HEADERS_PRODUCT_TABLE"
    hide-default-footer
    item-key="id"
    :items="promotion.detail.apdung_type_detail.options"
    loading-text="Đang tải dữ liệu"
    :loading="false"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    show-select
  >
    <template v-slot:header.sale_price="{ header }">
      {{ header.text }}
      <v-tooltip
        v-if="promotion.detail.type === 5"
        bottom
        content-class="elevation-4 px-2"
        max-width="400"
        nudge-top="5px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="ml-1"
            color="grey"
            dark
            size="14px"
            v-bind="attrs"
            v-on="on"
          >
            mdi-information
          </v-icon>
        </template>
        <span>Số lượng tối đa trong 1 khoảng thời gian khuyến mại</span>
      </v-tooltip>
    </template>

    <template v-slot:body="{ items, isSelected, select }">
      <tbody v-if="items.length > 0">
        <tr v-for="item in items" :key="item.id" class="pa-2">
          <td>
            <v-checkbox
              :input-value="isSelected(item)"
              style="margin:0px;padding:0px"
              color="primary"
              hide-details
              @click="select(item, !isSelected(item))"
            ></v-checkbox>
          </td>

          <td>
            <div class="py-2">
              <div class="font-weight-bold">{{ item.name }}</div>
              <div>{{ item.description }}</div>
            </div>
          </td>

          <td>{{ item.SKU }}</td>

          <td>{{ item.price | formatCurrency }}</td>

          <td>
            <v-text-field
              custom-class="text-body-1 mt-0 pt-0"
              style="width: 78px"
              dense
              flat
              hide-details
              outlined
              placeholder="% giảm"
              solo
              type="number"
              :value="
                Math.round(
                  ((item.price - item.sale_price) / item.price) * 100 * 10
                ) / 10
              "
              @change="
                item.sale_price = item.price - (item.price * $event) / 100
              "
            ></v-text-field>
            <!-- <template v-else>
                      {{ item.unit_price | formatCurrency }}
                    </template> -->
          </td>

          <!-- <td>{{ item.rebate_amount }}</td> -->

          <td>
            <tp-input-price
              custom-class="text-body-1 mt-0 pt-0"
              custom-style="width: 120px"
              dense
              flat
              hide-details
              outlined
              placeholder="Giá sau giảm"
              solo
              :value="item.sale_price"
              @change="item.sale_price = $event"
            ></tp-input-price>
          </td>

          <td>{{ item.count_sold }}</td>

          <td>{{ item.total_sold }}</td>

          <td>
            <v-text-field
              custom-class="text-body-1 mt-0 pt-0"
              style="width:55px"
              dense
              flat
              hide-details
              outlined
              solo
              :value="item.max_sold"
              @change="item.max_sold = $event"
            ></v-text-field>
          </td>

          <td style="text-align: center;">
            {{ item.total_can_sell }}
          </td>

          <td style="text-align: center;">
            <v-btn color="red" icon @click="handleDeleteSku(item.id)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { HEADERS_PRODUCT_TABLE } from "@/modules/Promotion/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    internalSelectedProduct: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    promotion() {
      return this.$store.getters["PROMOTION/promotion"];
    }
  },

  data() {
    return {
      HEADERS_PRODUCT_TABLE
    };
  },

  methods: {
    handleDeleteSku(id) {
      const { apdung_type_detail } = this.promotion.detail;
      apdung_type_detail.options = apdung_type_detail.options.filter(
        item => item.id !== id
      );
      this.promotion.detail.apdung_type_detail = apdung_type_detail;
    }
  }
};
</script>
